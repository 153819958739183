@import url('https://fonts.googleapis.com/css2?family=Fira+Code:wght@300..700&family=Fira+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Overpass:ital,wght@0,100..900;1,100..900&display=swap');


/* Color variables */
:root {
  --secondary: #1C2541;
  --primary: #0B132B;
  --light: #C8B8DB;
  --tertiary: #EEE1B3;
  --periwinkleBlue: #6667AB;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body, html {
  background-color: var(--primary);
  height: 100vh;
  width: 100vw;
  scroll-behavior: smooth;
  overflow-x: hidden;
}

/* ANIMATION */

@keyframes animate-fade-up {
	0% {
		opacity: 0;
    transform: translateY(0.3rem);
	}
	100% {
		opacity: 1;
    transform: translateY(0rem);
	}
}

@keyframes animate-fade-down {
	0% {
		opacity: 0;
    transform: translateY(-0.7rem);
	}
	100% {
		opacity: 1;
    transform: translateY(0rem);
	}
}
/* HEADER */

.header-container {
  background-color: var(--primary);
  height: 10%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  z-index: 5;
}

.logo {
  margin-left: 1.5rem;
  margin-top: 0.75rem;
  animation-duration: 0.75s;
  animation-delay: 0.2s;
  animation-name: animate-fade-down;
  animation-timing-function: ease-in-out;
  animation-fill-mode: backwards;
}

#navbar {
  display: none;
}

#navbar button {
  font-size: 0.8rem;
  width: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: auto;
}

.header-container ul {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 15px;
  list-style-type: none;
  margin-right: 2rem;
}

.header-container li {
  margin: 0 15px;
  /* text-transform: uppercase; */
  font-family: 'Fira Code', Courier, monospace;
  animation-duration: 0.75s;
  animation-name: animate-fade-down;
  animation-timing-function: ease-in-out;
  animation-fill-mode: backwards;
}

.header-container li:nth-child(1) {
  animation-delay: 0.4s;
}

.header-container li:nth-child(2) {
  animation-delay: 0.5s;
}

.header-container li:nth-child(3) {
  animation-delay: 0.6s;
}

.header-container li:nth-child(4) {
  animation-delay: 0.7s;
}

.header-container li:nth-child(5) {
  animation-delay: 0.8s;
}

.header-container li:nth-child(6) {
  animation-delay: 0.9s;
}

.header-container a {
  text-decoration: none;
  color: var(--light);
  transform: scale(1.1);
  transition: all 250ms linear;
}

#navbar a:hover {
  color: #EEEEEE;
  border-bottom: 2px solid #EEEEEE;
}

#navbar a:active {
  color: var(--light);
  border-bottom: 2px solid var(--light);
}

.resume-download button:hover {
  box-shadow: 3px 3px var(--light);
}

/* PAGES */

.home, 
.about, 
.projects, 
.skills, 
.contact {
  color: var(--light);
  display: flex;
  width: 100%;
  font-family: 'Fira Sans', Helvetica, sans-serif;
}

/* HOME PAGE */

.home {
  height: 100vh;
  width: 100vw;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: space-evenly;
  animation-duration: 0.75s;
  animation-delay: 1s;
  animation-name: animate-fade-up;
  animation-timing-function: ease-in-out;
  animation-fill-mode: backwards;
}

.home-text .intro {
  font-family: 'Fira Code', Courier, monospace;
  font-size: 0.95rem;
  font-weight: 200;
  color: var(--tertiary);
}

.home-text {
  display: flex;
  flex-direction: column;
  margin: 1rem 3rem;
  justify-content: flex-start;
  gap: 1.5rem;
}

.home h1 {
  color: var(--light);
  letter-spacing: 0.15rem;
  font-family: 'Fira Sans', Helvetica, sans-serif;
  font-size: 2.5rem;
  font-weight: 600;
  max-width: 90vw;
  animation-duration: 0.75s;
  animation-delay: 1.1s;
  animation-name: animate-fade-up;
  animation-timing-function: ease-in-out;
  animation-fill-mode: backwards;
}

.home p {
  color: var(--light);
  font-size: 0.8rem;
  font-weight: 300;
  font-family: 'Fira Sans', Helvetica, sans-serif;
  letter-spacing: 0.075rem;
  line-height: 1.3rem;
  text-align: left;
  margin: auto;
  animation-duration: 0.75s;
  animation-delay: 1.2s;
  animation-name: animate-fade-up;
  animation-timing-function: cubic-bezier(0.26, 0.53, 0.74, 1.48);
  animation-fill-mode: backwards;
}

.home span {
  color: var(--tertiary);
  font-weight: 400;
}

.home-text button {
  align-self: flex-start;
  font-family: 'Fira Code', courier, monospace;
  font-size: 0.8rem;
  font-weight: 400;
  color: var(--tertiary);
  padding: 0.5rem 2rem;
  border: 1px solid var(--tertiary);
  animation-duration: 0.75s;
  animation-delay: 1.3s;
  animation-name: animate-fade-up;
  animation-timing-function: ease-in-out;
  animation-fill-mode: backwards;
}

/* ABOUT PAGE */

.about {
  justify-content: center;
  align-items: center;
  padding-bottom: 15vh;
  background-image: url('./assets/topography-background.svg');
}

.about h1 {
  text-align: left;
  color: var(--light);
  letter-spacing: 0.09rem;
  margin: 8rem auto 2rem auto;
  font-size: 1.5rem;
  padding-top: 10%;
  position: relative;
  font-family: 'Fira Code', Courier, monospace;
}

.about-main main {
  width: 80vw;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  font-family: 'Fira Sans', Helvetica, sans-serif;
  font-weight: 300;
  line-height: 1.2rem;
}

.about-section1,
.about-section2 {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  justify-content: center;
  align-items: center;
  margin: 2rem auto;
}

.aquarium-image {
  width: 80vw;
  border-radius: 5px;
  border: 2px solid var(--light);
}

.profile-image {
  width: 10rem;
  border-radius: 5px;
  filter: sepia(100%) hue-rotate(201deg) brightness(65%) contrast(100%) saturate(200%);
  outline: 1px solid #C8B8DB;
  outline-offset: 7px;
  transition: all 0.3s ease-in-out;
}

.profile-image:hover {
  filter: brightness(75%) contrast(100%) saturate(60%);
  outline: 1px solid #C8B8DB;
  outline-offset: 2.5px;
  transition: all 0.3s ease-in-out;
}

.about-text {
  text-align: left;
}

.about p {
  color: var(--light);
  letter-spacing: 0.075rem;
  font-size: 0.85rem;
}

/* PROJECTS PAGE */

.projects {
  flex-direction: column;
  padding-top: 8rem;
  margin-bottom: 20vh;
}

.projects main {
  display: flex;
  flex-direction: column;
  row-gap: 50px;
  justify-content: center;
  align-items: center;
}

.projects-header h1 {
  margin-bottom: 2rem;
  margin-left: 10vw;
  font-size: 1.5rem;
  width: 80vw;
  font-family: 'Fira Code', Courier, monospace;

}

.projects-header {
  margin-right: auto;
  position: relative;
}

.featured-project {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 80vw;
  border-radius: 10px;
  padding: 2rem;
  height: 200px;
  margin: 3rem auto;
  position: relative;
}

.featured-project-text {
  z-index: 3;
  padding-top: 3rem;
}
/* first and third featured projects */
.fp1, .fp3 {
  flex-direction: row-reverse;
  text-align: right;
}
/* second featured project */
.fp2 {
  text-align: left;
}

.featured-technologies p {
  color: var(--tertiary);
  font-weight: 100;
  font-size: 0.6rem;
}
/* first and third featured project's technologies */
.fp1 .featured-technologies,
.fp3 .featured-technologies {
  justify-content: flex-end;
}
/* featured thumbnail positioning */
.ft1, .ft2, .ft3 {
  position: absolute;
}

/* featured thumbnail image properties */

.ft1 img,
.ft2 img,
.ft3 img {
  border-radius: 5px;
  width: 20rem;
  opacity: 10%;
}
/* feature thumbnail hover design */
.ft1 img:hover,
.ft2 img:hover,
.ft3 img:hover {
  opacity: 80%;
}
/* feature thumbnail active design */
.ft1 img:active,
.ft2 img:active,
.ft3 img:active {
  opacity: 50%;
}

.featured-header {
  font-family: "Fira Code", Courier, monospace;
  font-weight: 300;
  font-size: 0.5rem;
}

.featured-title {
  font-size: 1.1rem;
}

.featured-project-text a {
  text-decoration: none;
  color: #fff;
}

.featured-desc {
  color: #fff;
  font-size: 0.75rem;
  font-weight: 200;
  background-color: var(--periwinkleBlue);
  padding: 0.5rem;
  width: 70vw;
  border-radius: 3px;
  margin-top: 0.5rem;
}

.featured-technologies {
  font-family: 'Fira Code', Courier, monospace;
  display: flex;
  flex-direction: row;
  column-gap: 0.5rem;
  margin-top: 0.8rem;
}

.featured-technologies p {
  font-size: 0.5rem;
}

.featured-buttons {
  display: flex;
  column-gap: 0.7rem;
}

.featured-buttons button {
  font-size: 0.6rem;
  padding: 0.2rem 01rem;
}
/* first and third featured project buttons */
.fp1 .featured-buttons,
.fp3 .featured-buttons {
  justify-content: flex-end;
}

.other-projects-header {
  margin: 6rem auto 1rem auto;
  font-size: 1.2rem;
  display: flex;
  justify-content: center;
  width: 100vw;
}

.project-item {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: 20rem;
  background-color: var(--secondary);
  border-radius: 10px;
  padding: 6px;
  max-width: 75vw;
}

.project-thumbnail img {
  border-radius: 10px;
  box-shadow: 2px 2px 5px var(--periwinkleBlue);
  width: 12rem;
}

.project-thumbnail img:hover {
  box-shadow: 2px 2px 5px var(--light);
}

.project-thumbnail img:active {
  box-shadow: none;
  transform: translate(2px, 2px);
}

.project-text {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.project-text a {
  text-decoration: none;
}

.project-text h1:hover {
  color: var(--light);
}

.project-text h1 {
  text-align: center;
  font-size: 1.3rem;
  color: #dddddd;
}

.project-description {
  display: flex;
  flex-direction: row;
  column-gap: 1.2rem;
  line-height: 1.2rem;
  max-width: 90%;
  flex-wrap: wrap;
  justify-content: center;
  color: var(--tertiary);
  font-size: 0.75rem;
  margin-top: 0.5rem;
  font-family: "Fira Code", Courier, monospace;
}

.project-buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  column-gap: 1rem;
  width: 90vw;
  margin-top: 0.5rem;
}

.project-buttons button {
  width: 7rem;
  padding: 0.4rem 0.2rem;
  font-size: 0.8rem;
}

.btn-live:hover,
.btn-code:hover {
  box-shadow: 3px 3px var(--light);
}

/* SKILLS PAGE */

.skills {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8rem 0;
  background-image: url('./assets/topography-background.svg');
}

.skills-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 3rem;
}

.skills h1 {
  text-align: left;
  color: var(--light);
  letter-spacing: 0.09rem;
  margin-bottom: 2.5rem;
  padding-top: 10%;
  font-size: 1.5rem;
  position: relative;
  width: 80vw;
  font-family: 'Fira Code', Courier, monospace;
}

.skills p {
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.5rem;
  text-align: left;
  color: var(--light);
  letter-spacing: 0.065rem;
  width: 80vw;
  margin-bottom: 1rem;
}

.skills a {
  text-decoration: none;
  font-weight: 400;
  color: var(--tertiary);
}

.skills a:hover {
  text-decoration: underline;
}

.skills-body {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  column-gap: 2.5rem;
  row-gap: 1.5rem;
  width: 80vw;
}

.skills-item {
  font-family: 'Fira Code', Courier, monospace;
  font-size: 1.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.7rem;
  color: #ddd;
}

.skills-icon {
  width: 1.8rem;
  height: 1.8rem;
}

/* CONTACT PAGE */

.contact {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8rem 2rem;
}

.contact h1 {
  color: var(--light);
  letter-spacing: 0.09rem;
  margin-bottom: 2rem;
  font-size: 1.5rem;
  padding-top: 10%;
  position: relative;
  width: 80vw;
  text-align: left;
  font-family: 'Fira Code', Courier, monospace;
}

.contact-text p {
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.5rem;
  color: var(--light);
  letter-spacing: 0.065rem;
  text-align: left;
}

.contact-text p {
  padding-bottom: 1.5rem;
}

.contact-info {
  padding: 1.5rem 0;
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  letter-spacing: 0.05rem;
  font-weight: 300;
}

button {
  background-color: transparent;
  border: 1px solid var(--light);
  padding: 0.3rem 2.5rem;
  color: var(--light);
  font-size: 1rem;
  font-family: 'Fira Sans', Helvetica, sans-serif;
  border-radius: 5px;
  margin-top: 0.75rem;
  align-self: end;
}

button:hover {
  cursor: pointer;
  transform: translate(-3px, -3px);
  box-shadow: 3px 3px var(--tertiary);
  transition: all 120ms linear;
}

.contact-email button {
  align-self: flex-start;
  font-family: 'Fira Code', courier, monospace;
  font-size: 0.8rem;
  font-weight: 400;
  letter-spacing: 0.08rem;
  color: var(--tertiary);
  padding: 0.5rem 2rem;
  border: 1px solid var(--tertiary);
}


/* FOOTER */

.footer-container {
  background-color: var(--primary);
  width: 100%;
  height: 10vh;
  color: var(--tertiary);
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0;
  z-index: 5;
}

.footer-links {
  width: 60vw;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.footer-icon {
  height: 1.2rem;
  width: 1.2rem;
}

.footer-links a {
  text-decoration: none;
  color: var(--light);
  transition: all 200ms ease-in-out;
  animation-duration: 0.75s;
  animation-name: animate-fade-up;
  animation-timing-function: cubic-bezier(0.26, 0.53, 0.74, 1.48);
  animation-fill-mode: both;
}

.footer-links a:nth-child(1) {
  animation-delay: 1.4s;
}

.footer-links a:nth-child(2) {
  animation-delay: 1.5s;
}

.footer-links a:nth-child(3) {
  animation-delay: 1.6s;
}

.footer-links a:nth-child(4) {
  animation-delay: 1.7s;
}

.footer-links a:hover {
  color: var(--tertiary);
  transform: translateY(-5px);
}

.footer-links a:active {
  color: var(--tertiary);
}

/* ACCESSIBILITY PREFERENCE TO REDUCE ANIMATION */

@media only screen and (prefers-reduced-motion: reduce) {
  .animate { animation: none !important; }
}

/* MEDIUM SCREENS AND MOST TABLETS */

@media only screen and (min-width: 767px) {
  #navbar {
    display: block;
  }
  .header-container li {
    font-size: 0.8rem;
  }
  .home h1 {
    font-size: 3.5rem;
  }
  .home-text p {
    font-size: 1.1rem;
    font-weight: 200;
    line-height: 1.5rem;
    width: 80vw;
    letter-spacing: 0.07rem;
  }
  .home-text span {
    font-weight: 300;
  }
  .home-text button {
    padding: 1rem 1.7rem;
    font-size: 0.9rem;
  }
  .about h1,
  .projects h1,
  .skills h1,
  .contact h1 {
    font-size: 1.75rem;
  }
  .about p,
  .skills p,
  .contact p {
    font-size: 1.1rem;
    line-height: 2rem;
  }
  .about h1 {
    margin-top: 10rem;
    margin-bottom: 3rem;
  }
  .aquarium-image {
    width: 40vw;
  }
  .profile-image {
    width: 30vw;
  }
  .projects {
    padding-top: 10rem;
  }
  .projects main {
    flex-flow: row wrap;
    gap: 2rem;
    justify-content: center;
  }
  .projects-header {
    padding-bottom: 5%;
  }
  .featured-project {
    display: flex;
    flex-direction: row;
    background-color: var(--secondary);
    width: 80vw;
    border-radius: 10px;
    padding: 2rem;
    height: 230px;
    margin: 3.5rem auto;
    position: relative;
  }
  .featured-project-text {
    z-index: 3;
    padding-top: 0;
  }
  .fp1 .featured-project-text, 
  .fp3 .featured-project-text {
    margin-left: auto;
  }
  .fp2 .featured-project-text {
    margin-right: auto;
  }
  .fp1, .fp3 {
    flex-direction: row-reverse;
    text-align: right;
  }
  .fp2 {
    text-align: left;
  }
  .featured-technologies p {
    color: var(--tertiary);
    font-weight: 100;
    font-size: 0.6rem;
  }
  .fp1 .featured-technologies,
  .fp3 .featured-technologies {
    justify-content: flex-end;
  }
  .ft1, .ft2, .ft3 {
    position: absolute;
  }
  .ft1,
  .ft3 {
    left: 2rem;
    top: -1.5rem;
  }
  .ft2 {
    right: 2rem;
    top: -0.5rem;
  }
  .ft1 img,
  .ft2 img,
  .ft3 img {
    border-radius: 5px;
    width: 20rem;
    opacity: 40%;
  }
  .ft1 img:hover,
  .ft2 img:hover,
  .ft3 img:hover {
    opacity: 80%;
  }
  .ft1 img:active,
  .ft2 img:active,
  .ft3 img:active {
    opacity: 50%;
  }
  .featured-header {
    font-family: "Fira Code", Courier, monospace;
    font-weight: 400;
    font-size: 0.55rem;
    margin-top: 0;
  }
  .featured-title {
    font-family: 'Fira Sans', Helvetica, sans-serif;
  }
  .featured-project-text a {
    text-decoration: none;
    color: #fff;
  }
  .featured-desc {
    color: #fff;
    font-size: 0.75rem;
    font-weight: 300;
    background-color: var(--periwinkleBlue);
    padding: 0.5rem;
    width: 25rem;
    border-radius: 3px;
    margin-top: 0.5rem;
    line-height: 1rem;
  }
  .featured-technologies {
    font-family: 'Fira Code', Courier, monospace;
    display: flex;
    flex-direction: row;
    column-gap: 0.5rem;
    margin-top: 0.8rem;
  }
  .featured-technologies p {
    font-weight: 400;
    font-size: 0.55rem;
  }
  .featured-buttons {
    display: flex;
    column-gap: 0.5rem;
  }
  .featured-buttons button {
    font-size: 0.5rem;
    padding: 0.2rem 1rem;
  }
  .fp1 .featured-buttons,
  .fp3 .featured-buttons {
    justify-content: flex-end;
  }  
  .project-item {
    width: 40vw;
  }
  .project-thumbnail img {
    width: 12rem;
  }
  .project-text h1 {
    font-size: 1.5rem;
    margin-top: 1rem;
  }
  .project-buttons {
    margin-top: 0.1rem;
    column-gap: 1rem;
    width: 40vw;  
  }
  .project-buttons button {
    text-transform: uppercase;
    width: 8rem;
    padding: 0.5rem 0;
    font-size: 0.8rem;
  }
  .skills {
    padding: 10rem 0;
  }
  .skills-text {
    margin-bottom: 6rem;
  }
  .skills p {
    width: 75vw;
    margin-bottom: 2rem;
  }
  .skills-body {
    padding: 0 3rem;
  }
  .contact {
    padding: 10rem 0;
  }
  .contact-text {
    max-width: 75vw;
  }
  .contact-text p:last-of-type {
    padding: 2rem 0;
  }
  .contact-email button {
    padding: 1rem 1.7rem;
    font-size: 0.9rem;
  }
  .footer-container {
    height: 7vh;
  }
  .footer-links {
    width: 40vw;
  }
  .footer-icon {
    height: 1.2rem;
    width: 1.2rem;
  }
}

/* SMALLER DESKTOP */

@media only screen and (min-width: 992px) {
  .home {
    margin: 2rem 0;
  }
  .about h1 {
    margin-top: 8rem;
  }
  .skills {
    padding: 8rem 0;
  }
  .contact {
    padding: 8rem 0;
  }
}

/* DESKTOP */

@media only screen and (min-width: 1024px) {
  .home-text {
    gap: 0;
  }
  .home-text .intro {
    font-size: 1.2rem;
    padding-bottom: 1.5rem;
  }
  .home-text h1 {
    font-size: 4.5rem;
    padding-bottom: 2.5rem;
  }
  .home-text p {
    font-size: 1.1rem;
    font-weight: 300;
    line-height: 2rem;
  }
  .home-text span {
    font-weight: 400;
  }
  .home-text button {
    margin-top: 3rem;
  }
  .about h1 {
    margin-top: 10rem;
  }
  .aquarium-image {
    width: 40vw;
  }
  .about-section2 {
    display: flex;
    flex-direction: row;
  }
  .aquarium-image {
    width: 30vw;
  }
  .profile-image {
    width: 25vw;
  }
  .projects {
    padding-top: 0;
  }
  .featured-header {
    font-size: 0.55rem;
    font-weight: 400;
  }
  .featured-technologies p {
    font-weight: 400;
    font-size: 0.55rem;
  }
  .featured-project {
    width: 65vw;
  }
  .featured-desc {
    font-size: 0.75rem;
  }
  .featured-technologies p {
    font-weight: 400;
    font-size: 0.55rem;
  }
  .project-item {
    width: 22rem;
    height: 25rem;
  }
  .skills {
    padding: 10rem 0;
  }
  .contact {
    padding: 10rem 0;
  }
  .resume-download button {
    font-size: 1.5rem;
  }
  .footer-links {
    width: 60vw;
  }
}

/* NEST HUB AND OTHER LANDSCAPE TABLETS */

@media only screen and (min-width: 1024px) and (orientation: landscape) {
  .header-container li {
    font-size: 1.1rem;
  }
  .home {
    flex-direction: row;
    margin: 2rem 0;
  }
  .home-text .intro {
    padding-bottom: 1rem;
  }
  .home-text h1 {
    font-size: 4rem;
  }
  .home-text p {
    width: 80vw;
    letter-spacing: 0.03rem;
  }
  .about h1 {
    margin-top: 5rem;
  }
  .projects {
    padding-top: 10rem;
  }
  .project-thumbnail img {
    width: 17rem;
  }
  .project-description {
    margin-top: 0.5rem;
  }
  .skills {
    padding: 10rem 0;
  }
  .contact {
    padding: 10rem 0;
  }
}

/* iPAD PRO AND OTHER PORTRAIT TABLETS */

@media only screen and (min-width: 1024px) and (orientation: portrait) {
  .projects {
    padding-top: 10rem;
  }
  .project-thumbnail img {
    width: 17rem;
  }
  .project-description {
    margin-top: 0.5rem;
    font-size: 0.8rem;
  }
}

/* LARGER DESKTOP */

@media only screen and (min-width: 1200px) {
  .home {
    flex-direction: row;
  }
  .home-text p {
    width: 60vw;
  }
  .about h1 {
    margin-top: 5rem;
  }
  .projects {
    padding-top: 0;
  }
  .projects-header h1 {
    padding-top: 20%;
  }
  .featured-project {
    margin: 6rem;
  }
  .ft1 img,
  .ft2 img,
  .ft3 img {
  width: 25rem;
  }
  .ft1 img {
    top: 5rem;
    right: 0;
  }
  .ft3 {
    left: 2rem;
    top: -3.5rem;
  }
  .ft2 {
    right: 2rem;
    top: -2.5rem;
  }
  .featured-title {
    font-size: 1.2rem;
  }
  .featured-desc {
    font-size: 0.8rem;
    width: 30rem;
  }
  .featured-technologies p {
    font-size: 0.65rem;
    margin-top: -5px;
  }
  .featured-buttons button {
    font-size: 0.65rem;
    margin-top: 7px;
  }
  .other-projects-header {
    font-size: 1.5rem;
    font-weight: 400;
    letter-spacing: 0.05rem;
  }
  .project-thumbnail img {
    width: 15rem;
  }
  .project-description {
    margin-top: 2rem;
  }
  .skills {
    padding-top: 5rem;
  }
  .contact {
    padding: 5rem 0 8rem 0;
  }
  .resume-download button {
    font-size: 1.5rem;
  }
  .footer-links {
    width: 40vw;
  }
  .footer-icon {
    height: 1.2rem;
    width: 1.2rem;
  }
}

/* DESKTOP PORTRAIT ORIENTATION */

@media only screen and (min-width: 1200px) and (max-height: 800px) {
  .projects {
    padding-top: 0;
  }
  .project-thumbnail img {
    width: 17rem;
  }
  .project-description {
    margin-top: 0.5rem;
  }
}

/* WIDER SCREENS */

@media only screen and (min-width: 1440px) {
  .header-container li {
    font-size: 1rem;
  }
  .home {
    margin: 3rem 0;
  }
  .projects {
    padding-top: 0;
  }
  .featured-project {
    width: 60rem;
  }
  .featured-desc {
    font-size: .95rem;
    width: 40rem;
    line-height: 1.3rem;
  }
  .other-projects-header {
    font-size: 1.7rem;
  }
  .skills p,
  .skills-body {
    width: 70vw;
  }
  .footer-icon {
    height: 1.2rem;
    width: 1.2rem;
  }
}
